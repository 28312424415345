import React, { Component, useState } from "react";
import {
  Row,
  Tabs,
  Tab,
  Col,
  Card,
  Button,
  Accordion,
  ListGroup
} from "react-bootstrap";
import {Link} from "react-router-dom";
import ContactForm from "./../contact-form";
import Footer from "./../footer.js";
//import BSI from "./../images/BSi.png";
import BlueCircuit from "./../images/blueCircuit.png";
import LandingBanner from "./landing-banner.js";
import scrollToComponent from "react-scroll-to-component";
import BSICert from "./../certs/BSI_FM_558383_7222024.pdf";
// import QualityPolicy from './../policies/HSNiproQualityOperationsPolicyManual.pdf'
import ISOImage from './../images/revisions/ISOStockImage.jpg';
import FiveStars from './../images/revisions/FiveStars.jpg';
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import styled from "styled-components";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import QueryParamsDemo from "../scrollerComponent.js";

const HeaderText =
  `At NIPRO Technical Solutions, Quality is more than a classification. It is engrained into all aspects of our company culture and who we are.`;

  const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}

  class QualitySystems extends Component {
  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
        //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=quality-policy-statement" )
      {
        return("quality-policy-statement");
      }
      else if  (window.location.search == "?name=quality-culture" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        
        return("quality-culture");
      }
      else if  (window.location.search == "?name=hs-certifications" )
      {
        firstAccordionArrow = false;
        
        thirdAccordionArrow = true;
        return("hs-certifications");
      }
     else //if its default
     {
      return("quality-policy-statement");
     }    
    }

    return (
      <div>
        <QueryParamsDemo />  
        <div className="bodyContainer">
          <Row>
            <LandingBanner text={HeaderText} />
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={BlueCircuit}
                    className="img-responsive"
                    id="servicesImg"
                    alt="Repair Technician"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} id="servicesheaderp">
                  <div id="spacingDiv">
                    <br />
                    <h1 className="productshead">
                      Quality
                      Systems
                    </h1>
                    <p id="servicesHeaderText">
                      Quality Control is a vital aspect in the manufacturing
                      process of medical devices and beyond. Here at NIPRO Technical Solutions, we
                      take this responsibility seriously and hold ourselves to
                      the utmost levels of integrity for the quality services
                      provided to our clients.{" "}
                    </p>

                    <Button
                      variant="custom"
                      onClick={() =>
                        scrollToComponent(this.Quality, {
                          offset: 0,
                          align: "top",
                          duration: 1500
                        })
                      }
                    >
                      Learn More
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner />
          </Row>
          <Row
            id="serviceTabContainer"
            ref={section => {
              this.Quality = section;
            }}
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/*Accordion Start */}            
            <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
              className="flex-column flex-sm-row"
              id="accordion"
            >
              {/* Accordion 1 */}
              <Card title="Quality Policy Statement" id="firstTab" >
                <Card.Header className="accordionHeader">
              <Link to="/manufacturing/quality-systems?name=quality-policy-statement">
                <CustomToggle eventKey={"quality-policy-statement"} inputVariable={firstAccordionArrow}>
                <b>Quality Policy Statement</b>
                </CustomToggle>
                </Link>
                </Card.Header>

                <Accordion.Collapse eventKey={"quality-policy-statement"}>
                  <Card.Body>            
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                    Our dedication to Quality begins with our responsibility to ourselves as an organization by embracing our core values and a business-based Quality culture. We never forget the responsibility we all share as a manufacturer of medical devices and the patients whose life depends on them.
                    <br/><br/>
                    Our commitment to excellence goes beyond our customer and regulatory expectations and extends to the overall health and well-being of the company, ensuring the NIPRO Technical Solutions brand is associated with integrity and unsurpassed Quality.
                      <br />
                      <br />
                      {/* <i><a href={QualityPolicy} target="_blank" rel="noopener noreferrer">Quality Operations Policy Manual</a></i>*/}
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={ISOImage}
                      className="img-responsive tabImg"
                      alt="Exhange Products"
                    />
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 2 */}
              <Card title="Quality Culture" id="secondTab">
              <Card.Header className="accordionHeader">
                <Link to="/manufacturing/quality-systems?name=quality-culture">
                  <CustomToggle eventKey={"quality-culture"} inputVariable={secondAccordionArrow}>
                  <b>Quality Culture</b>
                  </CustomToggle>
                </Link>
              </Card.Header>
                <Accordion.Collapse eventKey={"quality-culture"}>
                  <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={FiveStars}
                      className="img-responsive tabImg"
                      id="smallTabImg"
                      alt="Repair Center"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Our dedication to quality takes the driver's seat as we
                      work with our clients. Through continuous quality
                      improvement measures, we provide unsurpassed satisfaction,
                      reliability and a highly regulated operation for
                      outsourced manufacturing solution needs.
                      <br />
                      <br />
                    </p>
                    <p>
                      <i>Business Quality Management System</i>
                      <br />

                      <i>
                        Client needs are supported at an elite level through
                        this companywide integrated application which surpasses
                        the traditional Quality Management System (QMS).
                      </i>
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 3 */}
              <Card title="H&S Certifications" id="thirdTab">
                <Card.Header className="accordionHeader">
              <Link to="/manufacturing/quality-systems?name=hs-certifications">
              <CustomToggle eventKey={"hs-certifications"} inputVariable={thirdAccordionArrow}>
              <b>Certifications</b>
                </CustomToggle >
                </Link>
                </Card.Header>
                <Accordion.Collapse eventKey={"hs-certifications"} >
                  <Card.Body>
              
                <Row style={{ paddingLeft: "15px", paddingRight: "10px" }}>
                  {/* commenting out logo 5/1/2024
                  <Col sm={12} md={4} lg={4} className="tabCol">
                    <img
                      src={BSI}
                      className="img-responsive"
                      alt="BSI logo"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                  */}
                    <p className="tabText">
                      All certifications are maintained through routine
                      surveillance auditing, internal auditing and standard
                      inspections by CSA (Compliance Safety and Accountability,
                      Canada), BSI America, and the FDA. The highest quality
                      standards are followed and regulated to not only maintain
                      but exceed compliancy standards.
                      <br />
                      <br />
                      As an FDA compliant and registered contract manufacturer
                      of class 2 medical devices and maintaining ISO 13485-2016
                      certification through BSI America, we are committed to
                      exceeding the highest industry standards of quality and
                      compliance. With our impeccable track record, NIPRO Technical Solutions can
                      seamlessly navigate the complexity of regulatory
                      compliances for our clients.
                    </p>
                    <br />
                    <br />
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <a
                          href={BSICert}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         <b>BSI ISO 13485-2016 Certification</b>
                        </a>
                        <p>
                        The BSI ISO 13485 standard meets the comprehensive requirements for a QMS, and both domestic and international regulations for medical devices.  
                        </p>
                      </ListGroup.Item>
                    </ListGroup>
                  
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
          </Row>
          <LandingBanner />
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default QualitySystems;
